<template>
  <rest
    :remote="remote"
    :table-columns="tableColumns"
    :form-initialize="formInitialize"
    :form-rules="formRules"
    :create-able="false"
    :exportable="true"
    :update-able="true"
    :delete-able="true"
    :listeners="listeners"
  >
    <template slot="tableColumn-dataKey" slot-scope="{row}">
      <el-popover placement="top-start" trigger="hover">
        <el-alert type="warning" :description="templateDescFormatter(row)" :closable="false"></el-alert>
        <el-tag slot="reference" type="warning" size="small">{{ templateNameFormatter(row) }}</el-tag>
      </el-popover>
    </template>
    <template slot="formItem-dataKey" slot-scope="{form, item}">
      <el-select
        v-model="form[item.prop]"
        placeholder="请选择"
        filterable
        clearable
        style="width: 100%"
        @change="handleDataKeyChange(form, item)"
      >
        <el-option
          v-for="t in templateMap"
          :key="t[0]"
          :label="t[1].dataKey + ' - ' + t[1].name"
          :value="t[1].dataKey"
        ></el-option>
      </el-select>
      <el-alert
        type="warning"
        :title="form.templateName"
        :description="form.templateDesc"
        :closable="false"
        style="margin-top: 10px;"
      ></el-alert>
    </template>
    <template slot="formItem-actions" slot-scope="{dataIndex, form, updateFn}">
      <el-button type="default" @click="handleDelete(form.id, updateFn)">删除账号</el-button>
      <el-button type="default" @click="handleUpdatePhone(form.id, form.phoneNo, updateFn)">修改手机号</el-button>
      <el-button type="default" @click="handleUpdatePassword(form.id, form.newPassword, updateFn)">修改密码</el-button>
      <el-button type="default" @click="handleUpdateStatus(form.id, form.status, updateFn)">修改状态</el-button>
      <el-button type="default" @click="handleUpdateAuth(form.id, form.authOpen, updateFn)">修改认证开关</el-button>

      <!--      {{dataIndex}} {{form}}-->
    </template>
  </rest>
</template>

<script>
import Rest from '../../components/Spring/Rest.vue'
import api from '@/api/channel'

export default {
  name: 'Accounts',
  components: { Rest },

  data () {
    return {
      remote: {
        path: 'nsdk/account',
        resource: this.$options.name,
        realtime: true
      },
      listeners: {
        handleFetchData: this.handleFetchData,
        beforeFormEdit: this.handleBeforeFormEdit
      },
      templateMap: new Map()
    }
  },

  computed: {
    appId () {
      return this.$store.getters.appId
    },
    appOptions () {
      return this.$store.getters.appOptions
    },
    formInitialize () {
      return {
        companyId: '',
        username: '',
        phoneNo: '',
        newPassword: '',
        status: '',
        appId: this.appId,
        authOpen: false,
        lastLoginTime: null,
        jwtTokenId: '',
        realName: '',
        idCard: '',
        birthday: ''
      }
    },
    tableColumns () {
      return [
        { label: 'ID', prop: 'id', hiddenForm: true },
        { label: '公司ID', prop: 'companyId', type: 'input' },
        { label: '用户名', prop: 'username', type: 'input' },
        { label: '手机号', prop: 'phoneNo', type: 'input' },
        {
          label: '状态',
          prop: 'status',
          type: 'select',
          options: [{ label: '注销中', value: 1 }, { label: '正常', value: 0 }]
        },
        { label: '注册时间', prop: 'createTime', type: 'date' },
        { label: '最后登录时间', prop: 'lastLoginTime', type: 'date' },
        { label: '姓名', prop: 'realName', type: 'input' },
        { label: '身份证号', prop: 'idCard', type: 'input' },
        {
          label: '认证开关',
          prop: 'authOpen',
          type: 'select',
          options: [{ label: '开启', value: true }, { label: '关闭', value: false }]
        },
        { label: '新密码', prop: 'newPassword', type: 'input' }
      ]
    }
  },

  mounted () {
    this.fetchExtConfigTemplates()
  },

  methods: {
    handleFetchData (dataList) {
      if (!dataList) {
        return []
      }
      for (const item of dataList) {
        const t = this.getTemplate(item.dataKey)
        item.templateName = t.name
        item.templateDesc = t.dataDesc
      }
      return dataList
    },

    fetchExtConfigTemplates () {
      api.getExtConfigTemplates().then(res => {
        const map = new Map()
        for (const t of res) {
          map.set(t.dataKey, t)
        }
        this.templateMap = map
      })
    },

    getTemplate (dataKey) {
      return this.templateMap.get(dataKey) || {}
    },

    changeFormTemplateData (form) {
      const t = this.getTemplate(form.dataKey)
      form.templateName = t.name
      form.templateDesc = t.dataDesc
      if (form.tableDataIndex < 0 || !form.dataValue) {
        form.dataValue = t.dataValue
      }
    },

    handleDataKeyChange (form, data) {
      this.changeFormTemplateData(form)
    },

    handleBeforeFormEdit (form) {
      this.changeFormTemplateData(form)
    },

    templateNameFormatter (row) {
      const t = this.getTemplate(row.dataKey)
      return t.name
    },

    templateDescFormatter (row) {
      const t = this.getTemplate(row.dataKey)
      return t.dataDesc || '--'
    },

    // 调用删除接口
    handleDelete (accountId, fn) {
      api.deleteAccount(accountId).then(response => {
        if (response.code === 1) {
          this.$message.success(response.msg)
        } else {
          this.$message.error(response.msg)
        }
        fn()
      })
    },

    // 调用修改密码接口
    handleUpdatePassword (accountId, password, fn) {
      api.updatePassword({ accountId, password }).then(response => {
        if (response.code === 1) {
          this.$message.success(response.msg)
        } else {
          this.$message.error(response.msg)
        }
        fn()
      })
    },

    // 调用换绑手机号接口
    handleUpdatePhone (accountId, phoneNo, fn) {
      const data = {
        accountId: accountId,
        phone: phoneNo
      }
      api.updatePhone(data).then(response => {
        if (response.code === 1) {
          this.$message.success(response.msg)
        } else {
          this.$message.error(response.msg)
        }
        fn()
      })
    },

    // 调用修改状态接口
    handleUpdateStatus (accountId, status, fn) {
      api.updateStatus({ accountId, status }).then(response => {
        if (response.code === 1) {
          this.$message.success(response.msg)
        } else {
          this.$message.error(response.msg)
        }
        fn()
      })
    },

    // 调用修改认证开关接口
    handleUpdateAuth (accountId, authOpen, fn) {
      api.updateAuth({ accountId, authOpen }).then(response => {
        if (response.code === 1) {
          this.$message.success(response.msg)
        } else {
          this.$message.error(response.msg)
        }
        fn()
      })
    }
  }
}
</script>

<style scoped>
</style>
