<template>
  <rest
    :remote="remote"
    :table-columns="tableColumns"
    :form-initialize="formInitialize"
    :form-rules="formRules"
    :create-able="false"
    :exportable="true"
    :update-able="true"
    :delete-able="true"
    :listeners="listeners"
  >
    <template slot="tableColumn-dataKey" slot-scope="{row}">
      <el-popover placement="top-start" trigger="hover">
        <el-alert type="warning" :description="templateDescFormatter(row)" :closable="false"></el-alert>
        <el-tag slot="reference" type="warning" size="small">{{ templateNameFormatter(row) }}</el-tag>
      </el-popover>
    </template>
    <template slot="formItem-dataKey" slot-scope="{form, item}">
      <el-select
        v-model="form[item.prop]"
        placeholder="请选择"
        filterable
        clearable
        style="width: 100%"
        @change="handleDataKeyChange(form, item)"
      >
        <el-option
          v-for="t in templateMap"
          :key="t[0]"
          :label="t[1].dataKey + ' - ' + t[1].name"
          :value="t[1].dataKey"
        ></el-option>
      </el-select>
      <el-alert
        type="warning"
        :title="form.templateName"
        :description="form.templateDesc"
        :closable="false"
        style="margin-top: 10px;"
      ></el-alert>
    </template>
    <template slot="formItem-actions" slot-scope="{dataIndex, form, updateFn}">
      <el-button type="default" @click="handleReissue(form.id, updateFn)">补单</el-button>
    </template>
  </rest>
</template>

<script>
import Rest from '../../components/Spring/Rest.vue'
import api from '@/api/channel'

export default {
  name: 'Orders',
  components: { Rest },

  data () {
    return {
      remote: {
        path: 'nsdk/pay',
        resource: this.$options.name,
        realtime: true
      },
      listeners: {
        handleFetchData: this.handleFetchData,
        beforeFormEdit: this.handleBeforeFormEdit
      },
      templateMap: new Map()
    }
  },

  computed: {
    appId () {
      return this.$store.getters.appId
    },
    appOptions () {
      return this.$store.getters.appOptions
    },
    formInitialize () {
      return {
        id: '',
        ufoOrderId: '',
        platformOrderId: '',
        appId: '',
        channelId: '',
        accountId: '',
        roleId: '',
        roleName: '',
        roleLevel: '',
        serverId: '',
        serverName: '',
        productId: '',
        productName: '',
        productDesc: '',
        channelProductId: '',
        price: '',
        currency: '',
        ip: '',
        payPlatformType: '',
        tradeType: '',
        merchantId: '',
        merchantName: '',
        merchantAppId: '',
        notifyUrl: '',
        notifyCount: '',
        envType: '',
        status: '',
        createTime: null,
        commitTime: null,
        finishTime: null
      }
    },
    tableColumns () {
      return [
        { label: '订单ID', prop: 'id', hiddenForm: true },
        { label: 'UFO订单ID', prop: 'ufoOrderId', type: 'input' },
        { label: '支付平台订单ID', prop: 'platformOrderId', type: 'input' },
        { label: '应用ID', prop: 'appId', type: 'input' },
        { label: '渠道ID', prop: 'channelId', type: 'input' },
        { label: '账号ID', prop: 'accountId', type: 'input' },
        { label: '角色ID', prop: 'roleId', type: 'input' },
        { label: '角色名称', prop: 'roleName', type: 'input' },
        { label: '价格', prop: 'price', type: 'input' },
        {
          label: '支付平台类型',
          prop: 'payPlatformType',
          type: 'select',
          options: [
            { label: '微信', value: 1 },
            { label: '支付宝', value: 2 },
            { label: '苹果', value: 3 },
            { label: '银联', value: 4 }
          ]
        },
        {
          label: '交易类型',
          prop: 'tradeType',
          type: 'select',
          options: [
            { label: '微信小游戏客服支付', value: 1 },
            { label: '微信虚拟支付', value: 2 },
            { label: '微信小游戏二维码支付', value: 3 },
            { label: '微信jsapi支付', value: 4 },
            { label: 'H5支付', value: 5 },
            { label: '转账', value: 6 },
            { label: '原生支付', value: 7 },
            { label: '二维码支付', value: 8 }
          ]
        },
        { label: '商户ID', prop: 'merchantId', type: 'input' },
        { label: '商户名称', prop: 'merchantName', type: 'input' },
        { label: '商户AppID', prop: 'merchantAppId', type: 'input' },
        {
          label: '环境类型',
          prop: 'envType',
          type: 'select',
          options: [
            { label: '正式', value: 0 },
            { label: '沙盒', value: 1 }
          ]
        },
        {
          label: '状态',
          prop: 'status',
          type: 'select',
          options: [
            { label: '订单失败', value: -1 },
            { label: '创建订单', value: 0 },
            { label: '已支付', value: 1 },
            { label: '已完成', value: 2 },
            { label: '正在扣费中', value: 3 },
            { label: '已取消', value: 4 }
          ]
        },
        { label: '创建时间', prop: 'createTime', type: 'date' },
        { label: '提交时间', prop: 'commitTime', type: 'date' },
        { label: '完成时间', prop: 'finishTime', type: 'date' }
      ]
    }

  },

  mounted () {
    this.fetchExtConfigTemplates()
  },

  methods: {
    handleFetchData (dataList) {
      if (!dataList) {
        return []
      }
      for (const item of dataList) {
        const t = this.getTemplate(item.dataKey)
        item.templateName = t.name
        item.templateDesc = t.dataDesc
      }
      return dataList
    },

    fetchExtConfigTemplates () {
      api.getExtConfigTemplates().then(res => {
        const map = new Map()
        for (const t of res) {
          map.set(t.dataKey, t)
        }
        this.templateMap = map
      })
    },

    getTemplate (dataKey) {
      return this.templateMap.get(dataKey) || {}
    },

    changeFormTemplateData (form) {
      const t = this.getTemplate(form.dataKey)
      form.templateName = t.name
      form.templateDesc = t.dataDesc
      if (form.tableDataIndex < 0 || !form.dataValue) {
        form.dataValue = t.dataValue
      }
    },

    handleDataKeyChange (form, data) {
      this.changeFormTemplateData(form)
    },

    handleBeforeFormEdit (form) {
      this.changeFormTemplateData(form)
    },

    templateNameFormatter (row) {
      const t = this.getTemplate(row.dataKey)
      return t.name
    },

    templateDescFormatter (row) {
      const t = this.getTemplate(row.dataKey)
      return t.dataDesc || '--'
    },

    // 调用删除接口
    handleReissue (orderId, fn) {
      const data = {
        orderId: orderId
      }
      api.reissue(data).then(response => {
        if (response.code === 1) {
          this.$message.success(response.msg)
        } else {
          this.$message.error(response.msg)
        }
        fn()
      })
    }
  }
}
</script>

<style scoped>
</style>
